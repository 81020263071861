import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import Books from "../components/books.js"
import Articles from "../components/articles.js"
import Courses from "../components/courses.js"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { format } from 'date-fns'


class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressPage
    this.headerImageData = (this.currentPage.acf.background === null) ? null : this.currentPage.acf.background.localFile.childImageSharp.fluid

  }


  render() {
    const currentPage = this.currentPage
    const footerPage = this.props.data.footerPage
    return (
      <Layout footer={footerPage} title={currentPage.title} headerImageData={this.headerImageData}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Sarah Kovner – " + currentPage.title}</title>
          <link
            rel="canonical"
            href={"https://sarahkovner.com/" + currentPage.slug}
          />
        </Helmet>


        <div class="block">
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </div>
        {currentPage.slug === "books" && currentPage.acf.books && (
          <section class="stripe">
            <div class="block">
              <Books value={currentPage.acf.books} detail={true} />
            </div>
          </section>
        )}

        {currentPage.slug === "articles" && currentPage.acf.articles && (
          <div>
            <section>
              <div class="block">
                <h2>Articles</h2>
                <Articles value={currentPage.acf.articles} />
              </div>
            </section>

            <section class="stripe">
              <div class="block">
                <h2>Book Chapters</h2>
                <Articles value={currentPage.acf.book_chapters} />
              </div>
            </section>

            <section class="stripe">
              <div class="block">
                <h2>Translations</h2>
                <Articles value={currentPage.acf.translations} />
              </div>
            </section>

            <section class="stripe">
              <div class="block">
                <h2>Reviews</h2>
                <Articles value={currentPage.acf.reviews} />
              </div>
            </section>
          </div>
        )}

        {currentPage.slug === "teaching" && currentPage.acf.courses && (
          <section>
            <div class="block">
              <Courses value={currentPage.acf.courses} />
            </div>
          </section>

        )}


        {currentPage.slug === "news" && currentPage.acf.events && (
          <section>
            <div class="block">

              {currentPage.acf.events.map(event =>   { 

const theDate = new Date(`${event.date.substring(0,4)}-${event.date.substring(4,6)}-${event.date.substring(6,8)}`)
const dateString = format(theDate, 'MMMM d, yyyy')
                return (
                <div className="event">
                  <span className="inner">
                   

                    {(event.image !== null &&
                    <div class="cover">
                    
                      <div class="inner">
                        
                          <Img
                            fluid={event.image.localFile.childImageSharp.fluid}
                            alt={event.title}
                            fadeIn={true}
                          />
                      </div>
                    
                    </div>
                      )}
                    
                    <div className="description">
                    <h3 dangerouslySetInnerHTML={{ __html: event.title }} />
                    <h4>{event.location  }, { dateString}</h4>
                    <div dangerouslySetInnerHTML={{ __html: event.description }} />
                    </div>

                  </span></div>
              )})}
            </div>

          </section>

        )}
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
      acf {


        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700, duotone: {
                highlight: "#263e5a",
                shadow: "#222222"
              },toFormat:PNG) {
                aspectRatio
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        

        courses {
          course_name
          course_type
          school
          dates
        }

        articles {
          title
          publication
          year
          citation
          link
        }
        book_chapters {
          title
          publication
          year
          citation
          link
        }
        translations {
          title
          publication
          year
          citation
          link
        }
        reviews {
          title
          publication
          year
          citation
          link
        }
        events {
          title
          date
          location
          link
          description
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  aspectRatio
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        books {
          title
          description
          publisher
          year
          cover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  aspectRatio
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }

    footerPage: wordpressPage(slug: { eq: "contact" }) {
      title
      content
      slug
    }
  }
`
